import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import PageHeader from './PageHeader.js'
import PageSection from './PageSection.js'
import styled from 'styled-components'
import { setContactSelected } from '../redux/contactsReducer.js'
import { setNotificationState } from '../redux/notificationsReducer'

import {
  DataTable,
  DataRow,
  DataHeader,
  DataCell,
  AttributeTable,
  AttributeRow,
  IconCell,
  IconClose,
  IconCheck,
} from './CommonStylesTables'

const Chip = styled.div`
  float: right;
  padding: 8px 10px;
  margin-right: 10px;
  line-height: 20px;
`

const PingBtn = styled.button`
  float: right;
  padding: 10px 12px;
  margin-right: 10px;
  line-height: 20px;
  color: ${(props) => props.theme.text_light};
  border: none;
  box-shadow: ${(props) => props.theme.drop_shadow};
  background: ${(props) => props.theme.primary_color};
`

const InteractionButton = styled.button`
  float: right;
  padding: 10px 12px;
  margin-right: 10px;
  line-height: 20px;
  color: ${(props) => props.theme.text_light};
  border: none;
  box-shadow: ${(props) => props.theme.drop_shadow};
  background: ${(props) => props.theme.primary_color};
`

function Contact(props) {
  const dispatch = useDispatch()
  const contactsState = useSelector((state) => state.contacts)
  const credentials = useSelector((state) => state.credentials.credentials)
  const contactSelected = contactsState.contactSelected

  const history = props.history
  const contactId = props.contactId

  useEffect(() => {
    if (contactsState.contacts) {
      for (let i = 0; i < contactsState.contacts.length; i++) {
        if (contactsState.contacts[i].contact_id === parseInt(contactId)) {
          dispatch(setContactSelected(contactsState.contacts[i]))
          break
        }
      }
    }
  }, [contactsState.contacts, contactId, dispatch])

  let [threadId, setThreadId] = useState('')

  async function trustPing() {
    const connectionId = contactSelected.Connections[0].connection_id
    props.sendRequest('CONTACT', 'PING', { connectionId: connectionId })
  }

  function openCredential(history, id) {
    if (history !== undefined) {
      history.push('/admin/credentials/' + id)
    }
  }

  function Pong() {
    if (
      contactSelected.Connections &&
      contactSelected.Connections[0].connection_id ==
        contactsState?.pongData?.connectionId
    ) {
      return (
        <Chip>
          <IconCheck alt="Pong" />
        </Chip>
      )
    } else {
      return <Chip>&nbsp;</Chip>
    }
  }

  const credentialRows = credentials.map((credential_record) => {
    if (
      contactSelected.Connections &&
      contactSelected.Connections[0].connection_id ===
        credential_record.connection_id
    ) {
      const credential_id = credential_record.credential_exchange_id
      const credentialState = credential_record.state.replaceAll('_', ' ') || ''
      const dateCreated =
        new Date(credential_record.created_at).toLocaleString() || ''

      let credentialName = ''
      if (
        credential_record.credential_proposal_dict !== null &&
        credential_record.credential_proposal_dict !== undefined
      ) {
        credentialName = credential_record.credential_proposal_dict.schema_name.replaceAll(
          '_',
          ' '
        )
      }
      return (
        <DataRow
          key={credential_id}
          onClick={() => {
            openCredential(history, credential_id)
          }}
        >
          <DataCell>{credentialName}</DataCell>
          <DataCell className="title-case">{credentialState}</DataCell>
          <DataCell>{dateCreated}</DataCell>
        </DataRow>
      )
    } else {
      return []
    }
  })

  const onDiscoverFeatures = () => {
    props.sendRequest('DISCOVER_FEATURES', 'GET', {
      connection_id: contactSelected.Connections[0].connection_id,
    })

    dispatch(
      setNotificationState({
        message: 'See the backend logs for the result.',
        type: 'notice',
      })
    )
  }

  return (
    <>
      <div id="contact">
        <PageHeader
          title={'Contact Details: ' + (contactSelected.label || '')}
        />
        <PageSection>
          <h2>General Information</h2>
          <AttributeTable>
            <tbody>
              <AttributeRow>
                <th>Contact ID:</th>
                <td>{contactSelected.contact_id || ''}</td>
              </AttributeRow>
              <AttributeRow>
                <th>Connection Status:</th>
                <td>
                  {contactSelected.Connections !== undefined
                    ? contactSelected.Connections[0].state || ''
                    : ''}
                </td>
              </AttributeRow>
            </tbody>
          </AttributeTable>
          <h2>Contact Information</h2>
          <AttributeTable>
            <tbody>
              <AttributeRow>
                <th>Name:</th>
                <td>{contactSelected.label || ''}</td>
              </AttributeRow>
            </tbody>
          </AttributeTable>
          <InteractionButton onClick={() => onDiscoverFeatures()}>
            Discover Features v2.0
          </InteractionButton>
          <PingBtn
            onClick={() => {
              trustPing()
            }}
          >
            Ping
          </PingBtn>
          <Pong />
        </PageSection>
        <PageSection>
          <DataTable>
            <thead>
              <DataRow>
                <DataHeader>Credential</DataHeader>
                <DataHeader>Status</DataHeader>
                <DataHeader>Date Issued</DataHeader>
              </DataRow>
            </thead>
            <tbody>{credentialRows}</tbody>
          </DataTable>
        </PageSection>
      </div>
    </>
  )
}

export default Contact
