import { DateTime } from 'luxon'
import React, { useEffect, useState, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import QRCode from 'qrcode.react'
import styled from 'styled-components'

import { setInvitation, setInvitationURL } from '../redux/invitationsReducer'
import { setNotificationState } from '../redux/notificationsReducer'

import {
  StyledPopup,
  InputBox,
  ModalMedium,
  ModalHeader,
  ModalContentWrapper,
  ModalContent,
  CloseBtn,
  Actions,
  CancelBtn,
  SubmitBtnModal,
  ModalLabel,
  InputFieldModal,
} from './CommonStylesForms'

const QR = styled(QRCode)`
  display: block;
  margin: auto;
  padding: 20px;
  width: 300px;
  height: 300px;
  background-color: white;
`

function FormEmploymentIssuance(props) {
  const dispatch = useDispatch()
  const contactsState = useSelector((state) => state.contacts)
  const invitationsState = useSelector((state) => state.invitations)
  const schemas = useSelector((state) => state.settings.schemas)
  const [connection, setConnection] = useState(null)

  const employmentForm = useRef(null)

  useEffect(() => {
    if (
      contactsState.contacts.length > 0 &&
      invitationsState.invitation &&
      invitationsState.invitationURL
    ) {
      //(AmmonBurgi) Loop through connections for each contact to find the connection that was created with the current invitation.
      for (let i = 0; i < contactsState.contacts.length; i++) {
        const connections = contactsState.contacts[i].Connections
        const invConnectionId = invitationsState.invitation.connection_id

        const currentConnection = connections.find(
          (connection) => connection.connection_id === invConnectionId
        )

        if (currentConnection && currentConnection.state === 'active') {
          setConnection(currentConnection)
          break
        }
      }
    }
  }, [
    contactsState.contacts,
    invitationsState.invitation,
    invitationsState.invitationURL,
  ])

  const handleClosingModal = () => {
    setConnection(null)
    //(AmmonBurgi) Set Invitation to default values to prevent a false connect
    dispatch(setInvitation({}))
    dispatch(setInvitationURL(''))

    props.closeEmploymentModal()
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    const form = new FormData(employmentForm.current)

    const employmentAttr = [
      {
        name: 'employee_given_names',
        value: form.get('employee_given_names'),
      },
      {
        name: 'employee_surnames',
        value: form.get('employee_surnames'),
      },
      {
        name: 'employment_type',
        value: form.get('employment_type'),
      },
      {
        name: 'employment_postal_code',
        value: form.get('employment_postal_code'),
      },
      {
        name: 'employment_country',
        value: form.get('employment_country'),
      },
      {
        name: 'employment_address',
        value: form.get('employment_address'),
      },
      {
        name: 'employment_region',
        value: form.get('employment_region'),
      },
      {
        name: 'employment_city',
        value: form.get('employment_city'),
      },
      {
        name: 'employment_start_date',
        value: Math.floor(
          DateTime.fromISO(form.get('employment_start_date')).ts / 1000
        ).toString(),
      },
      {
        name: 'employment_role',
        value: form.get('employment_role'),
      },
      {
        name: 'employment_role_description',
        value: form.get('employment_role_description'),
      },
      {
        name: 'employer_address',
        value: form.get('employer_address'),
      },
      {
        name: 'employer_postal_code',
        value: form.get('employer_postal_code'),
      },
      {
        name: 'employer_city',
        value: form.get('employer_city'),
      },
      {
        name: 'employer_country',
        value: form.get('employer_country'),
      },
      {
        name: 'employer_region',
        value: form.get('employer_region'),
      },
      {
        name: 'employer_name',
        value: form.get('employer_name'),
      },
    ]

    const schema = schemas.SCHEMA_EMPLOYMENT
    const schemaParts = schema.split(':')

    props.sendRequest('CREDENTIALS', 'ISSUE_USING_SCHEMA', {
      connectionID: connection.connection_id,
      schemaID: schema,
      schemaVersion: schemaParts[3],
      schemaName: schemaParts[2],
      schemaIssuerDID: schemaParts[0],
      attributes: employmentAttr,
    })

    handleClosingModal()
    dispatch(
      setNotificationState({
        message: 'Employment Credential is on its way!',
        type: 'notice',
      })
    )
  }

  return (
    <StyledPopup
      open={props.employmentModalIsOpen}
      closeOnDocumentClick
      onClose={props.closeEmploymentModal}
    >
      <ModalMedium className="modal">
        <ModalHeader>Issue Employment Credential</ModalHeader>
        <ModalContentWrapper>
          {connection ? (
            <ModalContent>
              <form onSubmit={handleSubmit} ref={employmentForm}>
                <InputBox>
                  <ModalLabel htmlFor="employee_given_names">
                    Employee Given Names
                  </ModalLabel>
                  <InputFieldModal
                    name="employee_given_names"
                    id="employee_given_names"
                    placeholder=""
                  />
                </InputBox>
                <InputBox>
                  <ModalLabel htmlFor="employee_surnames">
                    Employee Surnames
                  </ModalLabel>
                  <InputFieldModal
                    name="employee_surnames"
                    id="employee_surnames"
                    placeholder=""
                  />
                </InputBox>
                <InputBox>
                  <ModalLabel htmlFor="employment_type">
                    Employment Type
                  </ModalLabel>
                  <InputFieldModal
                    name="employment_type"
                    id="employment_type"
                    placeholder=""
                  />
                </InputBox>
                <InputBox>
                  <ModalLabel htmlFor="employment_postal_code">
                    Employment Postal Code
                  </ModalLabel>
                  <InputFieldModal
                    name="employment_postal_code"
                    id="employment_postal_code"
                    placeholder=""
                  />
                </InputBox>
                <InputBox>
                  <ModalLabel htmlFor="employment_address">
                    Employment Address
                  </ModalLabel>
                  <InputFieldModal
                    name="employment_address"
                    id="employment_address"
                    placeholder=""
                  />
                </InputBox>
                <InputBox>
                  <ModalLabel htmlFor="employment_country">
                    Employment Country
                  </ModalLabel>
                  <InputFieldModal
                    name="employment_country"
                    id="employment_country"
                    placeholder=""
                  />
                </InputBox>
                <InputBox>
                  <ModalLabel htmlFor="employment_region">
                    Employment Region
                  </ModalLabel>
                  <InputFieldModal
                    name="employment_region"
                    id="employment_region"
                    placeholder=""
                  />
                </InputBox>
                <InputBox>
                  <ModalLabel htmlFor="employment_city">
                    Employment City
                  </ModalLabel>
                  <InputFieldModal
                    name="employment_city"
                    id="employment_city"
                    placeholder=""
                  />
                </InputBox>
                <InputBox>
                  <ModalLabel htmlFor="employment_start_date">
                    Employment Start Date
                  </ModalLabel>
                  <InputFieldModal
                    type="date"
                    name="employment_start_date"
                    id="employment_start_date"
                    placeholder=""
                  />
                </InputBox>
                <InputBox>
                  <ModalLabel htmlFor="employment_role">
                    Employment Role
                  </ModalLabel>
                  <InputFieldModal
                    name="employment_role"
                    id="employment_role"
                    placeholder=""
                  />
                </InputBox>
                <InputBox>
                  <ModalLabel htmlFor="employment_role_description">
                    Employment Role Description
                  </ModalLabel>
                  <InputFieldModal
                    name="employment_role_description"
                    id="employment_role_description"
                    placeholder=""
                  />
                </InputBox>
                <InputBox>
                  <ModalLabel htmlFor="employer_address">
                    Employer Address
                  </ModalLabel>
                  <InputFieldModal
                    name="employer_address"
                    id="employer_address"
                    placeholder=""
                  />
                </InputBox>
                <InputBox>
                  <ModalLabel htmlFor="employer_postal_code">
                    Employer Postal Code
                  </ModalLabel>
                  <InputFieldModal
                    name="employer_postal_code"
                    id="employer_postal_code"
                    placeholder=""
                  />
                </InputBox>
                <InputBox>
                  <ModalLabel htmlFor="employer_city">Employer City</ModalLabel>
                  <InputFieldModal
                    name="employer_city"
                    id="employer_city"
                    placeholder=""
                  />
                </InputBox>
                <InputBox>
                  <ModalLabel htmlFor="employer_country">
                    Employer Country
                  </ModalLabel>
                  <InputFieldModal
                    name="employer_country"
                    id="employer_country"
                    placeholder=""
                  />
                </InputBox>
                <InputBox>
                  <ModalLabel htmlFor="employer_region">
                    Employer Region
                  </ModalLabel>
                  <InputFieldModal
                    name="employer_region"
                    id="employer_region"
                    placeholder=""
                  />
                </InputBox>
                <InputBox>
                  <ModalLabel htmlFor="employer_name">Employer Name</ModalLabel>
                  <InputFieldModal
                    name="employer_name"
                    id="employer_name"
                    placeholder=""
                  />
                </InputBox>
                <Actions>
                  <CancelBtn type="button" onClick={handleClosingModal}>
                    Cancel
                  </CancelBtn>
                  <SubmitBtnModal type="submit">Send</SubmitBtnModal>
                </Actions>
              </form>
            </ModalContent>
          ) : (
            <ModalContent>
              <p>
                Simply scan the following QR code to begin the issuance process:
              </p>
              {invitationsState.invitationURL ? (
                <div className="qr">
                  <p>
                    <QR
                      value={invitationsState.invitationURL}
                      size={256}
                      renderAs="svg"
                    />
                  </p>
                </div>
              ) : (
                <p>
                  <span>Loading...</span>
                </p>
              )}
            </ModalContent>
          )}
        </ModalContentWrapper>
        <CloseBtn onClick={handleClosingModal}>&times;</CloseBtn>
      </ModalMedium>
    </StyledPopup>
  )
}

export default FormEmploymentIssuance
