import { applyMiddleware, createStore, combineReducers, compose } from 'redux'
import thunk from 'redux-thunk'
// import { composeWithDevTools } from 'redux-devtools-extension'

import contactsReducer from './redux/contactsReducer'
import credentialsReducer from './redux/credentialsReducer'
import invitationsReducer from './redux/invitationsReducer'
import loginReducer from './redux/loginReducer'
import notificationsReducer from './redux/notificationsReducer'
import usersReducer from './redux/usersReducer'
import settingsReducer from './redux/settingsReducer'

const rootReducer = combineReducers({
  contacts: contactsReducer,
  credentials: credentialsReducer,
  invitations: invitationsReducer,
  login: loginReducer,
  notifications: notificationsReducer,
  users: usersReducer,
  settings: settingsReducer,
})

// Used for those who have the redux devtools extension installed
// const enhancers = [applyMiddleware(thunk), composeWithDevTools()]
// export default createStore(rootReducer, compose(...enhancers))

export default createStore(rootReducer, applyMiddleware(thunk))
