const initialState = {
  contacts: [],
  contact: {},
  connection: null,
  contactSelected: {},
  pingData: {},
  pongData: {},
}

const SET_CONTACTS = 'SET_CONTACTS'
const SET_CONTACT = 'SET_CONTACT'
const SET_CONNECTION = 'SET_CONNECTION'
const SET_CONTACT_SELECTED = 'SET_CONTACT_SELECTED'
const CLEAR_CONTACTS_STATE = 'CLEAR_CONTACTS_STATE'
const SET_PING_DATA = 'SET_PING_DATA'
const SET_PONG_DATA = 'SET_PONG_DATA'

export function setContacts(contacts) {
  return {
    type: SET_CONTACTS,
    payload: contacts,
  }
}

export function setPingData(pingData) {
  return {
    type: SET_PING_DATA,
    payload: pingData,
  }
}

export function setPongData(pongData) {
  return {
    type: SET_PONG_DATA,
    payload: pongData,
  }
}

export function setContact(contact) {
  return {
    type: SET_CONTACT,
    payload: contact,
  }
}

export function setConnection(connection) {
  return {
    type: SET_CONNECTION,
    payload: connection,
  }
}

export function setContactSelected(contact) {
  return {
    type: SET_CONTACT_SELECTED,
    payload: contact,
  }
}

export function clearContactsState() {
  return {
    type: CLEAR_CONTACTS_STATE,
    payload: null,
  }
}

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_CONTACTS:
      return { ...state, contacts: action.payload }

    case SET_PING_DATA:
      return { ...state, pingData: action.payload }

    case SET_PONG_DATA:
      return { ...state, pongData: action.payload }

    case SET_CONNECTION:
      return { ...state, connection: action.payload }

    case SET_CONTACT:
      return { ...state, contact: action.payload }

    case SET_CONTACT_SELECTED:
      return { ...state, contactSelected: action.payload }

    case CLEAR_CONTACTS_STATE:
      return initialState

    default:
      return state
  }
}
