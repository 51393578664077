import { DateTime } from 'luxon'
import React, { useEffect, useState, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import QRCode from 'qrcode.react'
import styled from 'styled-components'

import { setConnection } from '../redux/contactsReducer'
import { setInvitation, setInvitationURL } from '../redux/invitationsReducer'
import { setNotificationState } from '../redux/notificationsReducer'

import {
  StyledPopup,
  InputBox,
  ModalMedium,
  ModalHeader,
  ModalContentWrapper,
  ModalContent,
  CloseBtn,
  Actions,
  CancelBtn,
  SubmitBtnModal,
  ModalLabel,
  InputFieldModal,
} from './CommonStylesForms'

const QR = styled(QRCode)`
  display: block;
  margin: auto;
  padding: 20px;
  width: 300px;
  height: 300px;
  background-color: white;
`
const SampleDataLabel = styled.label`
  font-size: 1.5em;
  width: 35%;
  margin-right: 10px;
`

function FormRecoveryScoreIssuance(props) {
  const dispatch = useDispatch()
  const contactsState = useSelector((state) => state.contacts)
  const invitationsState = useSelector((state) => state.invitations)
  const schemas = useSelector((state) => state.settings.schemas)
  const connection = contactsState.connection

  const [recoveryScoreDetails, setRecoveryScoreDetails] = useState({})

  const recoveryScoreForm = useRef(null)

  useEffect(() => {
    if (
      contactsState.contacts.length > 0 &&
      invitationsState.invitation &&
      invitationsState.invitationURL
    ) {
      //(AmmonBurgi) Loop through connections for each contact to find the connection that was created with the current invitation.
      for (let i = 0; i < contactsState.contacts.length; i++) {
        const connections = contactsState.contacts[i].Connections
        const invConnectionId = invitationsState.invitation.connection_id

        const currentConnection = connections.find(
          (connection) => connection.connection_id === invConnectionId
        )

        if (currentConnection && currentConnection.state === 'active') {
          dispatch(setConnection(currentConnection))
          break
        }
      }
    }
  }, [
    contactsState.contacts,
    invitationsState.invitation,
    invitationsState.invitationURL,
  ])

  const handleClosingModal = () => {
    dispatch(setConnection(null))
    //(AmmonBurgi) Set Invitation to default values to prevent a false connect
    dispatch(setInvitation({}))
    dispatch(setInvitationURL(''))

    props.closeRecoveryScoreModal()
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    const form = new FormData(recoveryScoreForm.current)

    const recoveryScoreAttr = [
      {
        name: 'recovery_score',
        value: form.get('recovery_score') || '',
      },
      {
        name: 'resting_heart_rate',
        value: form.get('resting_heart_rate') || '',
      },
      {
        name: 'heart_rate_variability',
        value: form.get('heart_rate_variability') || '',
      },
      {
        name: 'respiratory_rate',
        value: form.get('respiratory_rate') || '',
      },
      {
        name: 'sleep_duration',
        value: form.get('sleep_duration') || '',
      },
      {
        name: 'data_date',
        value: form.get('data_date')
          ? Math.floor(
              DateTime.fromISO(form.get('data_date')).ts / 1000
            ).toString()
          : '',
      },
      {
        name: 'issuance_date',
        value: form.get('issuance_date')
          ? Math.floor(
              DateTime.fromISO(form.get('issuance_date')).ts / 1000
            ).toString()
          : '',
      },
      {
        name: 'device_type',
        value: form.get('device_type') || '',
      },
    ]

    const schema = schemas.SCHEMA_RECOVERY_SCORE
    const schemaParts = schema.split(':')

    props.sendRequest('CREDENTIALS', 'ISSUE_USING_SCHEMA', {
      connectionID: connection.connection_id,
      schemaID: schema,
      schemaVersion: schemaParts[3],
      schemaName: schemaParts[2],
      schemaIssuerDID: schemaParts[0],
      attributes: recoveryScoreAttr,
    })

    handleClosingModal()
    dispatch(
      setNotificationState({
        message: 'Recovery Score Credential is on its way!',
        type: 'notice',
      })
    )
  }

  const selectOption = (option) => {
    switch (option) {
      case 'option_one':
        setRecoveryScoreDetails({
          recovery_score: '98',
          resting_heart_rate: '46',
          heart_rate_variability: '93',
          respiratory_rate: '14',
          sleep_duration: '7.5',
          data_date: '2023-02-15',
          issuance_date: '2023-02-16',
          device_type: 'W',
        })

        break

      case 'option_two':
        setRecoveryScoreDetails({
          recovery_score: '77',
          resting_heart_rate: '50',
          heart_rate_variability: '98',
          respiratory_rate: '17',
          sleep_duration: '6.4',
          data_date: '2023-02-17',
          issuance_date: '2023-02-18',
          device_type: 'W',
        })

        break

      case 'option_three':
        setRecoveryScoreDetails({
          recovery_score: '82',
          resting_heart_rate: '43',
          heart_rate_variability: '95',
          respiratory_rate: '15',
          sleep_duration: '7.2',
          data_date: '2023-02-19',
          issuance_date: '2023-02-20',
          device_type: 'W',
        })

        break

      default:
        setRecoveryScoreDetails({
          recovery_score: '',
          resting_heart_rate: '',
          heart_rate_variability: '',
          respiratory_rate: '',
          sleep_duration: '',
          data_date: '',
          issuance_date: '',
          device_type: '',
        })
        break
    }
  }

  return (
    <StyledPopup
      open={props.recoveryScoreModalIsOpen}
      closeOnDocumentClick
      onClose={handleClosingModal}
    >
      <ModalMedium className="modal">
        <ModalHeader>Issue Recovery Score Credential</ModalHeader>
        <ModalContentWrapper>
          {connection ? (
            <ModalContent>
              <InputBox style={{ marginBottom: '25px' }}>
                <SampleDataLabel>Recovery Score Sample Sets</SampleDataLabel>
                <select
                  onChange={(e) => selectOption(e.target.value)}
                  className="dropdown-content"
                >
                  <option value="default">Import Player:</option>
                  <option value="option_one">Ty Jackson / 2023-02-15</option>
                  <option value="option_two">Ty Jackson / 2023-02-17</option>
                  <option value="option_three">Ty Jackson / 2023-02-19</option>
                </select>
              </InputBox>
              <form onSubmit={handleSubmit} ref={recoveryScoreForm}>
                <InputBox>
                  <ModalLabel htmlFor="recovery_score">
                    Recovery Score
                  </ModalLabel>
                  <InputFieldModal
                    name="recovery_score"
                    id="recovery_score"
                    defaultValue={recoveryScoreDetails.recovery_score}
                  />
                </InputBox>
                <InputBox>
                  <ModalLabel htmlFor="resting_heart_rate">
                    Resting Heart Rate
                  </ModalLabel>
                  <InputFieldModal
                    name="resting_heart_rate"
                    id="resting_heart_rate"
                    defaultValue={recoveryScoreDetails.resting_heart_rate}
                  />
                </InputBox>
                <InputBox>
                  <ModalLabel htmlFor="heart_rate_variability">
                    Heart Rate Variability
                  </ModalLabel>
                  <InputFieldModal
                    name="heart_rate_variability"
                    id="heart_rate_variability"
                    defaultValue={recoveryScoreDetails.heart_rate_variability}
                  />
                </InputBox>
                <InputBox>
                  <ModalLabel htmlFor="respiratory_rate">
                    Respiratory Rate
                  </ModalLabel>
                  <InputFieldModal
                    name="respiratory_rate"
                    id="respiratory_rate"
                    defaultValue={recoveryScoreDetails.respiratory_rate}
                  />
                </InputBox>
                <InputBox>
                  <ModalLabel htmlFor="sleep_duration">
                    Sleep Duration
                  </ModalLabel>
                  <InputFieldModal
                    name="sleep_duration"
                    id="sleep_duration"
                    defaultValue={recoveryScoreDetails.sleep_duration}
                  />
                </InputBox>
                <InputBox>
                  <ModalLabel htmlFor="data_date">Data Date</ModalLabel>
                  <InputFieldModal
                    type="date"
                    name="data_date"
                    id="data_date"
                    defaultValue={recoveryScoreDetails.data_date}
                  />
                </InputBox>
                <InputBox>
                  <ModalLabel htmlFor="issuance_date">Issuance Date</ModalLabel>
                  <InputFieldModal
                    type="date"
                    name="issuance_date"
                    id="issuance_date"
                    defaultValue={recoveryScoreDetails.issuance_date}
                  />
                </InputBox>
                <InputBox>
                  <ModalLabel htmlFor="device_type">Device Type</ModalLabel>
                  <InputFieldModal
                    name="device_type"
                    id="device_type"
                    defaultValue={recoveryScoreDetails.device_type}
                  />
                </InputBox>
                <Actions>
                  <CancelBtn type="button" onClick={handleClosingModal}>
                    Cancel
                  </CancelBtn>
                  <SubmitBtnModal type="submit">Send</SubmitBtnModal>
                </Actions>
              </form>
            </ModalContent>
          ) : (
            <ModalContent>
              <p>
                Simply scan the following QR code to begin the issuance process:
              </p>
              {invitationsState.invitationURL ? (
                <div className="qr">
                  <p>
                    <QR
                      value={invitationsState.invitationURL}
                      size={256}
                      renderAs="svg"
                    />
                  </p>
                </div>
              ) : (
                <p>
                  <span>Loading...</span>
                </p>
              )}
            </ModalContent>
          )}
        </ModalContentWrapper>
        <CloseBtn onClick={handleClosingModal}>&times;</CloseBtn>
      </ModalMedium>
    </StyledPopup>
  )
}

export default FormRecoveryScoreIssuance
