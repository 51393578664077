import { DateTime } from 'luxon'
import React, { useEffect, useState, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import QRCode from 'qrcode.react'
import styled from 'styled-components'

import { setConnection } from '../redux/contactsReducer'
import { setInvitation, setInvitationURL } from '../redux/invitationsReducer'
import { setNotificationState } from '../redux/notificationsReducer'

import {
  StyledPopup,
  InputBox,
  ModalMedium,
  ModalHeader,
  ModalContentWrapper,
  ModalContent,
  CloseBtn,
  Actions,
  CancelBtn,
  SubmitBtnModal,
  ModalLabel,
  InputFieldModal,
} from './CommonStylesForms'

const QR = styled(QRCode)`
  display: block;
  margin: auto;
  padding: 20px;
  width: 300px;
  height: 300px;
  background-color: white;
`
const SampleDataLabel = styled.label`
  font-size: 1.5em;
  width: 35%;
  margin-right: 10px;
`

function FormStrainScoreIssuance(props) {
  const dispatch = useDispatch()
  const contactsState = useSelector((state) => state.contacts)
  const invitationsState = useSelector((state) => state.invitations)
  const schemas = useSelector((state) => state.settings.schemas)
  const connection = contactsState.connection

  const [strainScoreDetails, setStrainScoreDetails] = useState({})

  const strainScoreForm = useRef(null)

  useEffect(() => {
    if (
      contactsState.contacts.length > 0 &&
      invitationsState.invitation &&
      invitationsState.invitationURL
    ) {
      //(AmmonBurgi) Loop through connections for each contact to find the connection that was created with the current invitation.
      for (let i = 0; i < contactsState.contacts.length; i++) {
        const connections = contactsState.contacts[i].Connections
        const invConnectionId = invitationsState.invitation.connection_id

        const currentConnection = connections.find(
          (connection) => connection.connection_id === invConnectionId
        )

        if (currentConnection && currentConnection.state === 'active') {
          dispatch(setConnection(currentConnection))
          break
        }
      }
    }
  }, [
    contactsState.contacts,
    invitationsState.invitation,
    invitationsState.invitationURL,
  ])

  const handleClosingModal = () => {
    dispatch(setConnection(null))
    //(AmmonBurgi) Set Invitation to default values to prevent a false connect
    dispatch(setInvitation({}))
    dispatch(setInvitationURL(''))

    props.closeStrainScoreModal()
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    const form = new FormData(strainScoreForm.current)

    const strainScoreAttr = [
      {
        name: 'strain_score',
        value: form.get('strain_score') || '',
      },
      {
        name: 'heart_rate',
        value: form.get('heart_rate') || '',
      },
      {
        name: 'calories_burned',
        value: form.get('calories_burned') || '',
      },
      {
        name: 'hr_zone_2_time',
        value: form.get('hr_zone_2_time') || '',
      },
      {
        name: 'hr_zone_3_time',
        value: form.get('hr_zone_3_time') || '',
      },
      {
        name: 'hr_zone_4_time',
        value: form.get('hr_zone_4_time') || '',
      },
      {
        name: 'sleep_duration',
        value: form.get('sleep_duration') || '',
      },
      {
        name: 'perceived_exertion',
        value: form.get('perceived_exertion') || '',
      },
      {
        name: 'active_minutes',
        value: form.get('active_minutes') || '',
      },
      {
        name: 'data_date',
        value: form.get('data_date')
          ? Math.floor(
              DateTime.fromISO(form.get('data_date')).ts / 1000
            ).toString()
          : '',
      },
      {
        name: 'issuance_date',
        value: form.get('issuance_date')
          ? Math.floor(
              DateTime.fromISO(form.get('issuance_date')).ts / 1000
            ).toString()
          : '',
      },
      {
        name: 'device_type',
        value: form.get('device_type') || '',
      },
    ]

    const schema = schemas.SCHEMA_STRAIN_SCORE
    const schemaParts = schema.split(':')

    props.sendRequest('CREDENTIALS', 'ISSUE_USING_SCHEMA', {
      connectionID: connection.connection_id,
      schemaID: schema,
      schemaVersion: schemaParts[3],
      schemaName: schemaParts[2],
      schemaIssuerDID: schemaParts[0],
      attributes: strainScoreAttr,
    })

    handleClosingModal()
    dispatch(
      setNotificationState({
        message: 'Strain Score Credential is on its way!',
        type: 'notice',
      })
    )
  }

  const selectOption = (option) => {
    switch (option) {
      case 'option_one':
        setStrainScoreDetails({
          strain_score: '19',
          heart_rate: '180',
          calories_burned: '2800',
          hr_zone_2_time: '10',
          hr_zone_3_time: '10',
          hr_zone_4_time: '40',
          sleep_duration: '7.5',
          perceived_exertion: '19',
          active_minutes: '60',
          data_date: '2023-02-15',
          issuance_date: '2023-02-16',
          device_type: 'W',
        })

        break

      case 'option_two':
        setStrainScoreDetails({
          strain_score: '15',
          heart_rate: '169',
          calories_burned: '2200',
          hr_zone_2_time: '10',
          hr_zone_3_time: '20',
          hr_zone_4_time: '30',
          sleep_duration: '6.4',
          perceived_exertion: '16',
          active_minutes: '60',
          data_date: '2023-02-17',
          issuance_date: '2023-02-18',
          device_type: 'W',
        })

        break

      case 'option_three':
        setStrainScoreDetails({
          strain_score: '17',
          heart_rate: '176',
          calories_burned: '2700',
          hr_zone_2_time: '5',
          hr_zone_3_time: '10',
          hr_zone_4_time: '45',
          sleep_duration: '7.2',
          perceived_exertion: '18',
          active_minutes: '60',
          data_date: '2023-02-19',
          issuance_date: '2023-02-20',
          device_type: 'W',
        })

        break

      default:
        setStrainScoreDetails({
          recovery_score: '',
          resting_heart_rate: '',
          heart_rate_variability: '',
          respiratory_rate: '',
          sleep_duration: '',
          data_date: '',
          issuance_date: '',
          device_type: '',
        })
        break
    }
  }

  return (
    <StyledPopup
      open={props.strainScoreModalIsOpen}
      closeOnDocumentClick
      onClose={handleClosingModal}
    >
      <ModalMedium className="modal">
        <ModalHeader>Issue Strain Score Credential</ModalHeader>
        <ModalContentWrapper>
          {connection ? (
            <ModalContent>
              <InputBox style={{ marginBottom: '25px' }}>
                <SampleDataLabel>Strain Score Sample Sets</SampleDataLabel>
                <select
                  onChange={(e) => selectOption(e.target.value)}
                  className="dropdown-content"
                >
                  <option value="default">Import Player:</option>
                  <option value="option_one">Ty Jackson / 2023-02-15</option>
                  <option value="option_two">Ty Jackson / 2023-02-17</option>
                  <option value="option_three">Ty Jackson / 2023-02-19</option>
                </select>
              </InputBox>
              <form onSubmit={handleSubmit} ref={strainScoreForm}>
                <InputBox>
                  <ModalLabel htmlFor="strain_score">Strain Score</ModalLabel>
                  <InputFieldModal
                    name="strain_score"
                    id="strain_score"
                    defaultValue={strainScoreDetails.strain_score}
                  />
                </InputBox>
                <InputBox>
                  <ModalLabel htmlFor="heart_rate">Heart Rate</ModalLabel>
                  <InputFieldModal
                    name="heart_rate"
                    id="heart_rate"
                    defaultValue={strainScoreDetails.heart_rate}
                  />
                </InputBox>
                <InputBox>
                  <ModalLabel htmlFor="calories_burned">
                    Calories Burned
                  </ModalLabel>
                  <InputFieldModal
                    name="calories_burned"
                    id="calories_burned"
                    defaultValue={strainScoreDetails.calories_burned}
                  />
                </InputBox>
                <InputBox>
                  <ModalLabel htmlFor="hr_zone_2_time">
                    Heart Rate Zone 2nd Time
                  </ModalLabel>
                  <InputFieldModal
                    name="hr_zone_2_time"
                    id="hr_zone_2_time"
                    defaultValue={strainScoreDetails.hr_zone_2_time}
                  />
                </InputBox>
                <InputBox>
                  <ModalLabel htmlFor="hr_zone_3_time">
                    Heart Rate Zone 3rd Time
                  </ModalLabel>
                  <InputFieldModal
                    name="hr_zone_3_time"
                    id="hr_zone_3_time"
                    defaultValue={strainScoreDetails.hr_zone_3_time}
                  />
                </InputBox>
                <InputBox>
                  <ModalLabel htmlFor="hr_zone_4_time">
                    Heart Rate Zone 4th Time
                  </ModalLabel>
                  <InputFieldModal
                    name="hr_zone_4_time"
                    id="hr_zone_4_time"
                    defaultValue={strainScoreDetails.hr_zone_4_time}
                  />
                </InputBox>
                <InputBox>
                  <ModalLabel htmlFor="sleep_duration">
                    Sleep Duration
                  </ModalLabel>
                  <InputFieldModal
                    name="sleep_duration"
                    id="sleep_duration"
                    defaultValue={strainScoreDetails.sleep_duration}
                  />
                </InputBox>
                <InputBox>
                  <ModalLabel htmlFor="perceived_exertion">
                    Perceived Exertion
                  </ModalLabel>
                  <InputFieldModal
                    name="perceived_exertion"
                    id="perceived_exertion"
                    defaultValue={strainScoreDetails.perceived_exertion}
                  />
                </InputBox>
                <InputBox>
                  <ModalLabel htmlFor="active_minutes">
                    Active Minutes
                  </ModalLabel>
                  <InputFieldModal
                    name="active_minutes"
                    id="active_minutes"
                    defaultValue={strainScoreDetails.active_minutes}
                  />
                </InputBox>
                <InputBox>
                  <ModalLabel htmlFor="data_date">Data Date</ModalLabel>
                  <InputFieldModal
                    type="date"
                    name="data_date"
                    id="data_date"
                    defaultValue={strainScoreDetails.data_date}
                  />
                </InputBox>
                <InputBox>
                  <ModalLabel htmlFor="issuance_date">Issuance Date</ModalLabel>
                  <InputFieldModal
                    type="date"
                    name="issuance_date"
                    id="issuance_date"
                    defaultValue={strainScoreDetails.issuance_date}
                  />
                </InputBox>
                <InputBox>
                  <ModalLabel htmlFor="device_type">Device Type</ModalLabel>
                  <InputFieldModal
                    name="device_type"
                    id="device_type"
                    defaultValue={strainScoreDetails.device_type}
                  />
                </InputBox>
                <Actions>
                  <CancelBtn type="button" onClick={handleClosingModal}>
                    Cancel
                  </CancelBtn>
                  <SubmitBtnModal type="submit">Send</SubmitBtnModal>
                </Actions>
              </form>
            </ModalContent>
          ) : (
            <ModalContent>
              <p>
                Simply scan the following QR code to begin the issuance process:
              </p>
              {invitationsState.invitationURL ? (
                <div className="qr">
                  <p>
                    <QR
                      value={invitationsState.invitationURL}
                      size={256}
                      renderAs="svg"
                    />
                  </p>
                </div>
              ) : (
                <p>
                  <span>Loading...</span>
                </p>
              )}
            </ModalContent>
          )}
        </ModalContentWrapper>
        <CloseBtn onClick={handleClosingModal}>&times;</CloseBtn>
      </ModalMedium>
    </StyledPopup>
  )
}

export default FormStrainScoreIssuance
