import Axios from 'axios'
import React, { useRef, useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import ReCAPTCHA from 'react-google-recaptcha'
import styled from 'styled-components'

// import PoweredByImage from '../assets/powered-by.png'
import honorImage from '../assets/honor.png'

import { setNotificationState } from '../redux/notificationsReducer'

import {
  Actions,
  SubmitBtnModal,
  PoweredBox,
  PoweredBy,
  Logo,
} from './CommonStylesForms'

const CompFormVerify = styled.div`
  box-sizing: border-box;
  padding-top: 30px;
`
const FormWrapper = styled.div`
  margin-left: auto;
  margin-right: auto;
  width: 65%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2%;
  background: white;
`

const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
`

const HeaderVerify = styled.div`
  color: ${(props) => props.theme.primary_color};
  border-bottom: 2px solid #ddd;
  margin-bottom: 30px;
  font-size: 1.9em;
  padding-bottom: 3px;
`

export const InputField = styled.input`
  width: stretch;
  height: 1.7em;
  font-size: 1.5em;
  box-sizing: border-box;
  color: ${(props) => props.theme.text_color};
`
const InputBox = styled.div`
  width: 53%;
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  margin-bottom: 20px;
`

const Label = styled.label`
  margin-right: 10px;
  font-size: 1.5em;
  line-height: 1.7em;
  color: ${(props) => props.theme.text_color};
`

function FormVerify(props) {
  const dispatch = useDispatch()
  const settingsState = useSelector((state) => state.settings)
  const notificationState = useSelector((state) => state.notifications)
  const logo = settingsState.logo

  const submitBtn = useRef()
  const credentialForm = useRef(null)
  const [recaptchaKey, setRecaptchaKey] = useState('')
  const [submitEmail, setSubmitEmail] = useState(false)

  const recaptchaRef = React.createRef()

  useEffect(() => {
    if (
      notificationState.notificationType === 'error' ||
      (notificationState.notificationType === 'warning' && submitBtn.current)
    ) {
      submitBtn.current.removeAttribute('disabled')
    }
  }, [notificationState.notificationType])

  const onBtnClick = (e) => {
    if (submitBtn.current) {
      submitBtn.current.setAttribute('disabled', 'disabled')
    }
  }

  useEffect(() => {
    Axios({
      method: 'GET',
      url: '/api/recaptcha/sitekey',
    })
      .then((res) => {
        if (res.data.error) {
          dispatch(
            setNotificationState({ message: res.data.error, type: 'error' })
          )
        } else {
          setRecaptchaKey(res.data['key'])
        }
      })
      .catch((err) => console.log(err))
  }, [setNotificationState, dispatch])

  const handleSubmit = (e) => {
    e.preventDefault()
    onBtnClick()
    const form = new FormData(credentialForm.current)

    if (!recaptchaRef.current.getValue()) {
      return dispatch(
        setNotificationState({
          message: 'Please verify Recaptcha!',
          type: 'warning',
        })
      )
    }

    Axios({
      method: 'POST',
      data: {
        email: form.get('email'),
        reCaptcha: recaptchaRef.current.getValue(),
      },
      url: '/api/email/verify',
    })
      .then((res) => {
        if (res.data.error) {
          dispatch(
            setNotificationState({ message: res.data.error, type: 'error' })
          )
        } else {
          setSubmitEmail(true)
        }
      })
      .catch((err) => console.log(err))
  }

  return (
    <CompFormVerify>
      {!submitEmail ? (
        <FormWrapper>
          <div className="avatar-container left-fold landing-col-6">
            {logo ? <Logo src={logo} alt="Logo" /> : <Logo />}
          </div>

          <HeaderVerify>
            Please submit your Email Address to be Verified
          </HeaderVerify>
          <Form onSubmit={handleSubmit} ref={credentialForm}>
            <InputBox>
              <Label htmlFor="email">Email</Label>
              <InputField
                type="email"
                name="email"
                id="email"
                placeholder="Example@example.org"
                required
              />
            </InputBox>
            {recaptchaKey ? (
              <ReCAPTCHA sitekey={recaptchaKey} ref={recaptchaRef} />
            ) : null}
            <Actions>
              <SubmitBtnModal type="submit" ref={submitBtn}>
                Submit
              </SubmitBtnModal>
            </Actions>
          </Form>
        </FormWrapper>
      ) : (
        <FormWrapper>
          <HeaderVerify>Verification Complete</HeaderVerify>
          <p>Email submitted. Please check your email!</p>
        </FormWrapper>
      )}
      <PoweredBox>
        <PoweredBy src={honorImage} alt="Honor Digital Rights Management" />
      </PoweredBox>
    </CompFormVerify>
  )
}

export default FormVerify
